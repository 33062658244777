import { EPermission } from '@/plugins/role-permissions/types/index';

export enum EPermissionSubject {
  HOME = 'home',
  PROMO = 'promo',
  STATS = 'stats',
  USERS = 'users',
  INVITE = 'invite',
  BALANCE = 'balance',
  SETTINGS = 'settings',
  WITHDRAW = 'withdraw',
  PAYMENTS = 'payments',
  DASHBOARD = 'dashboard',
  TOKEN_MANAGE = 'token_manage',
  TOKEN_PROMOTION = 'token_promotion',
  ONBOARDING = 'onboarding',
  P2E = 'p2e',
  H2E = 'h2e',
  STREAMERS = 'streamers',
  API = 'api'
}

export enum EPermissionSuperAdmin {
  SUPER_ADMIN_ROLES = 'super_admin_roles',
  SUPER_ADMIN_CASTODY = 'super_admin_castody',
  SUPER_ADMIN_DEPLOY = 'super_admin_deploy',
  SUPER_ADMIN_MINT = 'super_admin_mint',
  SUPER_ADMIN_LIVEKIT_SETTINGS = 'super_admin_livekit_settings'
}

export type EPermissionSubjectSuperAdmin =
  | EPermissionSubject
  | EPermissionSuperAdmin;

export enum EPermissionSubjectHome {
  TOKEN = 'token',
  COLORS = 'colors',
  INTEGRATION = 'integration'
}

export enum EPermissionSubjectSettings {
  SOCIAL = 'social',
  FEATURE = 'feature',
  LANGUAGE = 'language',
  CURRENCY = 'currency',
  EVENT = 'event',
  COMPANY_INFO = 'company_info',
  ROlES = 'roles'
}

export enum EPermissionSubjectTokenManage {
  WALLETS = 'wallets',
  TRANSACTIONS = 'transactions',
  LIQUIDITY_POOL = 'liquidity_pool'
}

export enum EPermissionSubjectApi {
  GET_TOKEN_PRICE_DATA = 'get_token_price_data',
  GET_CLIENTS_INFO = 'get_clients_info'
}

export type TPermissionSubjectSimple = EPermission[] | boolean;

export type TPermissionSubject<T extends string = string> =
  | Partial<Record<T, TPermissionSubjectSimple>>
  | TPermissionSubjectSimple;

export type IPermissionConfigDefault = Partial<
  Record<EPermissionSubject, TPermissionSubject>
>;

export interface IPermissionConfig extends IPermissionConfigDefault {
  [EPermissionSubject.HOME]: TPermissionSubject<EPermissionSubjectHome>;
  [EPermissionSubject.SETTINGS]: TPermissionSubject<EPermissionSubjectSettings>;
  [EPermissionSubject.TOKEN_MANAGE]: TPermissionSubject<EPermissionSubjectTokenManage>;
  [EPermissionSubject.API]: TPermissionSubject<EPermissionSubjectApi>;
}

export type TPermissionRuleMain = Lowercase<keyof typeof EPermissionSubject>;
export type TPermissionSuperAdmin = Lowercase<
  keyof typeof EPermissionSuperAdmin
>;
export type TSettingsRule = Lowercase<keyof typeof EPermissionSubjectSettings>;
export type THomeRule = Lowercase<keyof typeof EPermissionSubjectHome>;
export type TTokenManageRule = Lowercase<
  keyof typeof EPermissionSubjectTokenManage
>;
export type TApiRule = Lowercase<keyof typeof EPermissionSubjectApi>;

export type TPermissionRuleKey =
  | `settings ${TSettingsRule}`
  | `home ${THomeRule}`
  | `token_manage ${TTokenManageRule}`
  | `api ${TApiRule}`
  | TPermissionSuperAdmin
  | TPermissionRuleMain;
